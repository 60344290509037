import React from "react"
import { Head as HeadComponent } from "@bw/layouts"
import PageTemplate from "../../../templates/pageTemplate.jsx"
import ArticleTemplate from "../../../templates/articleTemplate.jsx"
import PoiTemplate from "../../../templates/poiTemplate.jsx"
import ProfileTemplate from "../../../templates/profileTemplate.jsx"
import JobTemplate from "../../../templates/jobTemplate.jsx"
import EventTemplate from "../../../templates/eventTemplate.jsx"
import axios from "axios"

export const Head = ({ location, pageContext, data }) => (
  <HeadComponent
    title="Prévisualisation"
    location={location}
    pageContext={pageContext}
    doNotIndex={true}
  />
)

const PagePreviewTemplate = ({ params, location }) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [data, setData] = React.useState(null)
  const [isSuccess, setIsSuccess] = React.useState(false)

  React.useEffect(() => {
    axios
      .get(
        `/api/collections/${params.type}/entries/${params.id}${location.search}`,
        {
          headers: {
            "X-Statamic-Live-Preview": true,
          },
        }
      )
      .then(response => {
        const data = {
          ...response.data.data,
          header: response.data.data?.header?.map(content => ({
            ...content,
            __typename:
              content.type.charAt(0).toUpperCase() + content.type.slice(1),
          })),
          content: response.data.data?.content?.map(content => ({
            ...content,
            __typename:
              content.type.charAt(0).toUpperCase() + content.type.slice(1),
          })),
        }

        setData({
          data: {
            page: data,
          },
          pageContext: {
            locale: data.locale,
            translations: [],
          },
        })
        setIsSuccess(true)
      })
      .catch(e => {
        setIsSuccess(false)
      })
      .finally(() => setIsLoading(false))
  }, [params, location])

  if (isLoading) {
    return "...chargement"
  }

  if (!isSuccess) {
    return (
      <div style={{ padding: "10px" }}>
        Erreur de chargement. Voici ce qui cause habituellement ce problème:
        <ul>
          <li>La page n'est pas publiée</li>
          <li>La page n'a pas encore été enregistrée</li>
        </ul>
        Si tout ceci est en ordre, vous pouvez faire une demande de support en
        indiquant les informations suivantes:
        <blockquote>
          type de contenu: {params.type}
          <br />
          identifiant:{params.id}
        </blockquote>
      </div>
    )
  }

  switch (params.type) {
    case "articles":
      return <ArticleTemplate data={data.data} pageContext={data.pageContext} />
    case "pages":
      return <PageTemplate data={data.data} pageContext={data.pageContext} />
    case "pois":
      return <PoiTemplate data={data.data} pageContext={data.pageContext} />
    case "profiles":
      return <ProfileTemplate data={data.data} pageContext={data.pageContext} />
    case "jobs":
      return <JobTemplate data={data.data} pageContext={data.pageContext} />
    case "events":
      return <EventTemplate data={data.data} pageContext={data.pageContext} />
    default:
      return "Il n'y a pas de prévisualition pour cette collection."
  }
}

export default PagePreviewTemplate
