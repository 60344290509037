import StatamicObject from "./statamicObject"
import Poi from "./poi"
import i18n from "i18next"
import dateFormat from "../utils/dateFormat"

export default class Job extends StatamicObject {
  constructor(data) {
    super(data)

    this.duration = data.duration
    this.description = data.description
    this.occupancyRate = data.occupancyRate
    this.typeOfDuration = data.typeOfDuration.value
    this.duration = data.duration
    this.startDate = data.startDate
    this.poi = data.poi
  }

  getStartDate() {
    if (!this.startDate) {
      return i18n.t("job.dateToBeDetermined")
    } else {
      return dateFormat(this.startDate, i18n.language)
    }
  }

  getDuration() {
    if (this.typeOfDuration === "indefinite") {
      return i18n.t("job.undeterminedDuration")
    } else {
      return this.duration
    }
  }

  getPoi() {
    if (!this.poi) {
      return null
    }

    return new Poi(this.poi)
  }
}
