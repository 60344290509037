import React from "react"
import { Link, graphql } from "gatsby"
import { Layout, Head as HeadComponent } from "@bw/layouts"
import { Spacer, Section } from "@bw/bits"
import { Cartouche } from "@bw/partials"
import { PageHeader, Content } from "@bw/modules"
import parse from "html-react-parser"
import JobModel from "../models/job"
import { PillMarker } from "@bw/icons"
import { useTranslation } from "react-i18next"

export const Head = ({ location, pageContext, data }) => (
  <HeadComponent
    title={data.page.metaTitle || data.page.title}
    image={data.page.image?.localImage?.childImageSharp?.original?.src}
    location={location}
    pageContext={pageContext}
    description={data.page.metaDescription}
    doNotIndex={data.page.doNotIndex}
  />
)

const JobTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { page } = data
  const Job = new JobModel(page)
  const Poi = Job.getPoi()

  return (
    <Layout {...{ pageContext }}>
      <PageHeader
        title={Job.title}
        subtitle={`${Job.getDuration()} - ${Job.occupancyRate}`}
      />
      {Poi && (
        <Section>
          <Cartouche>
            <Link to={Poi.url} target="_blank" rel="noreferrer">
              <Spacer direction="row">
                <PillMarker />
                <div>
                  <div>
                    <b>{t("job.locationTitle")}</b>
                  </div>
                  {Poi.title}
                </div>
              </Spacer>
            </Link>
            <div>
              <div>
                <b>{t("job.durationTitle")}</b>
              </div>
              <div>{Job.getDuration()}</div>
              <div>{Job.getStartDate()}</div>
            </div>
            <div>
              <div>
                <b>{t("job.contactTitle")}</b>
              </div>
              {Poi.email && (
                <div>
                  <a href={`mailto:${Poi.email}`}>{Poi.email}</a>
                </div>
              )}
              {Poi.phone && (
                <div>
                  <a href={`tel:${Poi.phone}`}>{Poi.phone}</a>
                </div>
              )}
            </div>
          </Cartouche>
        </Section>
      )}
      {Job.description && (
        <Section>
          <Content>{parse(Job.description)}</Content>
        </Section>
      )}
    </Layout>
  )
}

export default JobTemplate

export const jobQuery = graphql`
  query jobQuery($id: String!) {
    page: collectionJobs(id: { eq: $id }) {
      id
      title
      metaTitle
      metaDescription
      image {
        localImage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      doNotIndex

      jobType {
        slug
      }
      description
      duration
      occupancyRate
      poi {
        id
        title
        street
        phone
        email
        city
        zipCode
      }
      startDate
      typeOfDuration {
        value
      }
    }
  }
`
