import { Link, graphql } from "gatsby"
import React from "react"
import { Layout, Head as HeadComponent } from "@bw/layouts"
import { Section, Spacer } from "@bw/bits"
import { Cartouche } from "@bw/partials"
import { PillMarker } from "@bw/icons"
import { useTranslation } from "react-i18next"
import { PageHeader } from "@bw/modules"
import EventModel from "models/event"
import { Content } from "@bw/modules/mapModules"

export const Head = ({ location, pageContext, data }) => (
  <HeadComponent
    title={data.page.metaTitle || data.page.title}
    image={data.page.image?.localImage?.childImageSharp?.original?.src}
    location={location}
    pageContext={pageContext}
    description={data.page.metaDescription}
    doNotIndex={data.page.doNotIndex}
  />
)

const EventTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { page, mount } = data
  const Event = new EventModel(page)
  const Pois = Event.getPois()

  /**
   * Generate the breadcrumbs
   */
  const breadcrumbsLinks = React.useMemo(() => {
    const links = [
      {
        title: t("homepage"),
        to: "/",
      },
    ]

    if (mount) {
      links.push({
        title: mount.title,
        to: mount.slug,
      })
    }

    links.push({
      title: page.title,
      to: page.url,
    })

    return links
  }, [t, mount, page])

  let dates = Event.getDateSpan()
  dates = dates.map((date, i) => (
    <div>
      {dates.length === 2 && i === 0
        ? t("dates:fromDate", { date })
        : dates.length === 2 && i === 1
        ? t("dates:toDate", { date })
        : date}
    </div>
  ))

  return (
    <Layout {...{ pageContext }}>
      <PageHeader
        suptitle={dates}
        title={Event.title}
        breadcrumbs={breadcrumbsLinks}
      />
      {Pois.length > 0 && (
        <Section>
          <Cartouche>
            {Pois.map((Poi, i) => (
              <Link to={Poi.url} target="_blank" rel="noreferrer">
                <Spacer direction="row">
                  <PillMarker />
                  <div>
                    <div>
                      <b>{t("job.locationTitle")}</b>
                    </div>
                    {Poi.title}
                  </div>
                </Spacer>
              </Link>
            ))}
          </Cartouche>
        </Section>
      )}
      {/*
       * Loading the Contents modules, ex Content or CallToAction, TeasersLatest
       */}
      <Content modules={page.content} />
    </Layout>
  )
}

export default EventTemplate

export const eventQuery = graphql`
  query eventQuery($id: String!) {
    page: collectionEvents(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      image {
        localImage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      doNotIndex

      teaserImage {
        url
      }

      startDate
      startTime
      endDate
      endTime

      poi {
        id
        url
        title
      }

      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }
        idAttribute
        classAttribute

        ...CallToActionModuleFragment
        ...ContentModuleFragment
        ...ContentWithImageModuleFragment
        ...GalleryModuleFragment
        ...ContentOnColumnsModuleFragment
        ...DownloadsModuleFragment
        ...TeasersManualModuleFragment
        ...LogoGridModuleFragment
        ...ImageModuleFragment
        ...VideoModuleFragment
        ...FormModuleFragment
        ...TimelineModuleFragment
        ...ListOfPoisModuleFragment
        ...ListOfArticlesModuleFragment
        ...ListOfProfilesModuleFragment
        ...ListOfJobsModuleFragment
        ...ListOfEventsModuleFragment
        ...PredefinedModuleModuleFragment
      }
    }

    mount: collectionPages(mount: { handle: { eq: "events" } }) {
      slug
      title
    }
  }
`
