import React from "react"
import { Link, graphql } from "gatsby"
import { Layout, Head as HeadComponent } from "@bw/layouts"
import { Spacer, Section, CmsImage, Portrait } from "@bw/bits"
import { Cartouche } from "@bw/partials"
import { PillMarker } from "@bw/icons"
import { PageHeader } from "@bw/modules"
import { useTranslation } from "react-i18next"
import ProfileModel from "models/profile"
import { Content } from "@bw/modules/mapModules"

export const Head = ({ location, pageContext, data }) => (
  <HeadComponent
    title={data.page.metaTitle || `${data.page.firstName} ${data.page.title}`}
    image={data.page.image?.localImage?.childImageSharp?.original?.src}
    location={location}
    pageContext={pageContext}
    description={data.page.metaDescription}
    doNotIndex={data.page.doNotIndex}
  />
)

const ProfileTemplate = ({ data, pageContext }) => {
  const { page } = data
  const { t } = useTranslation()
  const Profile = new ProfileModel(page)
  const Pois = Profile.getPois()

  return (
    <Layout {...{ pageContext }}>
      {/*
       * Loading the Contents modules, ex Content or CallToAction, TeasersLatest
       */}
      <PageHeader
        suptitle={t("profile.suptitle")}
        title={`${page.firstName} ${page.title}`}
        subtitle={page.role}
        portrait={
          page.portrait ? (
            <Portrait
              image={
                <CmsImage
                  src={page.portrait}
                  alt={`${page.firstName} ${page.title}`}
                />
              }
            />
          ) : undefined
        }
      />
      {Pois.length > 0 && (
        <Section>
          <Cartouche>
            {Pois.map((Poi, i) => (
              <Link to={Poi.url} target="_blank" rel="noreferrer">
                <Spacer direction="row">
                  <PillMarker />
                  <div>
                    <div>
                      <b>{t("job.locationTitle")}</b>
                    </div>
                    {Poi.title}
                  </div>
                </Spacer>
              </Link>
            ))}
          </Cartouche>
        </Section>
      )}
      <Content modules={page.content} />
    </Layout>
  )
}

export default ProfileTemplate

export const profileQuery = graphql`
  query profileQuery($id: String!) {
    page: collectionProfiles(id: { eq: $id }) {
      title
      firstName
      role
      portrait {
        localImage {
          childImageSharp {
            gatsbyImageData(width: 160, height: 160)
          }
        }
      }
      metaTitle
      metaDescription
      image {
        localImage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      doNotIndex

      poi {
        title
        url
      }

      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }
        idAttribute
        classAttribute

        ...CallToActionModuleFragment
        ...ContentModuleFragment
        ...ContentWithImageModuleFragment
        ...GalleryModuleFragment
        ...ContentOnColumnsModuleFragment
        ...DownloadsModuleFragment
        ...TeasersManualModuleFragment
        ...LogoGridModuleFragment
        ...ImageModuleFragment
        ...VideoModuleFragment
        ...FormModuleFragment
        ...TimelineModuleFragment
        ...ListOfPoisModuleFragment
        ...ListOfArticlesModuleFragment
        ...ListOfProfilesModuleFragment
        ...ListOfJobsModuleFragment
        ...ListOfEventsModuleFragment
        ...PredefinedModuleModuleFragment
      }
    }
  }
`
