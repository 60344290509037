import { graphql } from "gatsby"
import React from "react"
import { Layout, Head as HeadComponent } from "@bw/layouts"
import { PageHeader } from "@bw/modules"
import { Avatar, CmsImage } from "@bw/bits"
import { useTranslation } from "react-i18next"
import dateFormat from "../utils/dateFormat"
import { Content } from "@bw/modules/mapModules"

export const Head = ({ location, pageContext, data }) => (
  <HeadComponent
    title={data.page.metaTitle || data.page.title}
    image={data.page.image?.localImage?.childImageSharp?.original?.src}
    location={location}
    pageContext={pageContext}
    description={data.page.metaDescription}
    doNotIndex={data.page.doNotIndex}
  />
)

const ArticleTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { page, mount } = data

  /**
   * Generate the breadcrumbs
   */
  const breadcrumbsLinks = React.useMemo(() => {
    const links = [
      {
        title: t("homepage"),
        to: "/",
      },
    ]

    if (mount) {
      links.push({
        title: mount.title,
        to: mount.slug,
      })
    }

    links.push({
      title: page.title,
      to: page.url,
    })

    return links
  }, [t, mount, page])

  return (
    <Layout {...{ pageContext }}>
      <PageHeader
        suptitle={
          page.date ? dateFormat(page.date, pageContext.locale) : undefined
        }
        title={page.title}
        breadcrumbs={breadcrumbsLinks}
        subtitle={page.authors.map((author, i) => (
          <Avatar
            key={i}
            image={
              author.avatar?.url ? (
                <CmsImage src={author.avatar?.url} alt="" />
              ) : undefined
            }
            name={author.name}
          />
        ))}
      />
      <Content modules={page.content} />
    </Layout>
  )
}

export default ArticleTemplate

export const articleQuery = graphql`
  query articleQuery($id: String!) {
    page: collectionArticles(id: { eq: $id }) {
      url
      title
      metaTitle
      metaDescription
      date
      locale
      image {
        localImage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      doNotIndex
      teaserExcerpt
      teaserImage {
        url
      }
      authors {
        name
        avatar {
          url
        }
      }
      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }
        idAttribute
        classAttribute

        ...CallToActionModuleFragment
        ...ContentModuleFragment
        ...ContentWithImageModuleFragment
        ...GalleryModuleFragment
        ...ContentOnColumnsModuleFragment
        ...DownloadsModuleFragment
        ...TeasersManualModuleFragment
        ...LogoGridModuleFragment
        ...ImageModuleFragment
        ...VideoModuleFragment
        ...FormModuleFragment
        ...TimelineModuleFragment
        ...ListOfPoisModuleFragment
        ...ListOfArticlesModuleFragment
        ...ListOfProfilesModuleFragment
        ...ListOfJobsModuleFragment
        ...ListOfEventsModuleFragment
        ...PredefinedModuleModuleFragment
      }
    }

    mount: collectionPages(mount: { handle: { eq: "articles" } }) {
      slug
      title
    }
  }
`
