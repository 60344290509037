import StatamicObject from "./statamicObject"
import Poi from "./poi"

export default class Profile extends StatamicObject {
  constructor(data) {
    super(data)
    Object.assign(this, data)
  }

  getPois() {
    return this.poi ? this.poi.map(poi => new Poi(poi)) : []
  }
}
